export const useSearch = () => {
    const fuzzyMatch = (value: string, searchTerm: string) => {
        const searchTokens = searchTerm.split(' ')
        const valueNormalized = value.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
        for (const token of searchTokens) {
            const tokenNormalized = token.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
            const regex = tokenNormalized
                ? new RegExp(
                    tokenNormalized.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
                    'i')
                : /.*/
            if (!regex.test(valueNormalized)) {
                return false
            }
        }
        return true
    }

    return { fuzzyMatch }
}
